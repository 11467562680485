<!---- 
  -- This layout is inherited by all other layouts. It sets basic
  -- functionality that every page of utheory requires, including:
  --  * error/exception handling
  --  * analytics tracking
  --  * initializing tailwind
  ---->
<script context="module" lang="ts">
  import type { Load } from '@sveltejs/kit';

  export const load: Load = ({ session }) => {
    if (session.MAINTENANCE_MODE) return { status: 503 };
    return {};
  };
</script>

<script lang="ts">
  import '../app.postcss';
  import { isInIFrame } from 'shared/src/utils/isInIFrame';
  import * as Sentry from '@sentry/browser';
  import { BrowserTracing } from '@sentry/tracing';
  import SimpleModal from '$lib/components/SimpleModal.svelte';
  import Hr from '$lib/components/Hr.svelte';
  import { page } from '$app/stores';
  import { onMount } from 'svelte';

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_CLIENT_DSN,
    integrations: [new BrowserTracing()],
    environment: import.meta.env.VITE_ENVIRONMENT,
    tunnel: `/error-tracing`,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // Let's start with 1% of requests.
    tracesSampleRate: import.meta.env.VITE_ENVIRONMENT === 'PRODUCTION' ? 0.01 : 0.1,
    enabled: import.meta.env.VITE_ENVIRONMENT !== 'LOCAL',
  });

  let iFrameWarningModal: SimpleModal;
  let isIFrame = isInIFrame();
  onMount(() => {
    if (isIFrame) {
      iFrameWarningModal.open();
    }
  });
</script>

<SimpleModal bind:this={iFrameWarningModal} dismissable={true}>
  <div slot="title" class="card-padding text-red text-lg">Warning! iFrame detected.</div>
  <Hr />
  <div class="card-padding article">
    <p>
      Running uTheory in an iFrame may result in work not saving. uTheory must be run in its own
      browser tab, not in an iFrame.
      <a href={$page.url.toString()} target="_blank" class="a">Click here</a> to open uTheory in a new
      tab.
    </p>
  </div>
</SimpleModal>
<slot />
